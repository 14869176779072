import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/kaes_blog_header.png");
const section_1 = require("../../../assets/img/blogs/kaes_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/kaes_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/kaes_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/kaes_blog_img_4.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="How Knowledge AI Drives Greater Efficiency in Employee Support"
        description="Boost workplace productivity with Knowledge AI, powered by LLMs. Fast and easy information access for employees and service desk agents. Learn more now!"
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt kaes_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "text-align-center"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-white">
                    How knowledge AI can improve the efficiency of the employee
                    support team
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-white">
                    How knowledge AI can
                    <br /> improve the efficiency of the <br />
                    employee support team
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why is knowledge management difficult in the modern
                  workplace?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How does inefficient knowledge management impact user
                  experience?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How does Knowledge AI help overcome knowledge discovery
                  challenges?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How does Knowledge AI work to improve knowledge search?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. What are the use cases of Knowledge AI?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Benefits of Knowledge AI for your modern workplace
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Workativ and Knowledge AI - an outstanding combination to
                  redefine your workplace
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                While investigating why the client project had little progress,
                you discovered that your development teams had resource issues.
                Let’s say a sudden necessity was to get some essential software
                applications, and it brings up a couple of pieces of work for
                your DevOps teams.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                All this mundane work can entail preparing a presentation for
                the manager’s approval, sending a letter to HR for budget
                allocation, then HR writing to the CFO for approval, connecting
                back to the finance team for disbursal, and so on and so forth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These are back-and-forth processes and interconnected 一 a lot
                of interactions happen with{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  HR and IT support.
                </a>{" "}
                A single silo results in information slipping through the cracks
                and making it hard for a new person to take the process ahead.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, do you agree that knowledge is key? If information is not
                accessible, productivity issues come up at large. For the same
                reason, your{" "}
                <a href="https://workativ.com/conversational-ai-platform/features">
                  employee support
                </a>{" "}
                team can be forced to handle the repeated issues and delay
                customer service delivery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/contact-us">
                  Knowledge AI, a large language model or LLM-powered solution
                  for conversational AI or chatbot for service desk,
                </a>{" "}
                makes it easy for your team to search for information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Maybe you have a self-serve platform in your workplace and
                leverage automation to some extent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the power of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  generative AI and large language models
                </a>
                unleashes great efforts to redefine how your employee support
                team works and helps you build resilience into operational
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you do not find Knowledge AI quite convincing for the growth
                of your business, we can give you more reasons why fast
                information delivery or knowledge discovery is essential for a
                modern workplace today to work and deliver business results.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why is knowledge management difficult in the modern workplace?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employees need information to work - this is a universal truth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, when knowledge is fragmented and lies in a silo, knowledge
                is scarce.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A support team cannot work. Your employees look clueless and sit
                idle for hours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, why this happens?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Organizations are not able to manage knowledge effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Today, the number of productivity tools has just gone up. On top
                of it, organizations also use different business tools, such as
                CRM, ERP, ITSM platforms, etc.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Communications across these tools are not synced to provide an
                  integrated, unified view of the information.
                </li>
                <li className="font-section-normal-text-testimonials">
                  In a hybrid work setting, one’s time zone does not match the
                  others. If anyone is unavailable, knowledge delivery can
                  easily be affected.
                </li>
                <li className="font-section-normal-text-testimonials">
                  There is rarely anyone who can take responsibility for keeping
                  knowledge articles updated and organized. So,{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support">
                    knowledge management
                  </a>{" "}
                  becomes difficult.
                </li>
                <li className="font-section-normal-text-testimonials">
                  We can say knowledge is multichannel in more instances rather
                  than knowledge being omnichannel.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Organizations dedicate time to legacy systems.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                As a result,{" "}
                <a href="https://workativ.com/conversational-ai-platform/virtual-agent">
                  self-serve
                </a>{" "}
                cannot reach its full potential and offer employees the
                information they need to perform a task.
              </p>
              <BlogCta
                ContentCta="Automate your enterprise employee support with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The other way, it increases the workload on the employee support
                team.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does inefficient knowledge management impact user
                experience?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge management not done right can result in information
                being old and ineffective.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When this type of KM is used to{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  build chatbot
                </a>{" "}
                conversations such as FAQs or customized responses, employee
                support can become of no help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The generated responses may either contain out-of-date or
                incomplete information for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say an internal project management tool your employees use has a
                new version. It means it has new features and functionalities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now imagine the existing resource center or KB has no new
                updates added to the document.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A person unaware of the changes to the application would have
                difficulty taking control of the app and face productivity
                issues. It urges him to turn to the self-serve platform.
                Unfortunately, it can only surface the same old tips and provide
                no real help, forcing him to seek an agent’s help.
              </p>
              <h3 className="font-section-sub-header-small-home">
                It is a deeper problem inside.{" "}
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" limited context window for a normal chatbot"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee productivity is wasted if an answer is unavailable
                through FAQs on the{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  self-serve platform
                </a>{" "}
                or a ticket is on the waiting list for more than a few minutes,
                if not hours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In many instances, businesses are seen handling the same issues
                repeatedly, causing overutilization of agent time and forcing
                agents to add more time to resolve a common issue. At the same
                time, employees face disengagement due to loss of productivity.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does Knowledge AI help overcome knowledge discovery
                challenges?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Over the past few months, we have heard much about Generative
                AI, which has incredible abilities close to human-level
                intelligence to understand natural language queries and give
                back NLP-based answers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Built on neural networks of deep learning techniques similar to
                human brains, Generative AI can detect the context in a query
                and populate the answer for users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                  However, large language models used as ‘as is’ can be used for
                  Q&A conversations based on the data they are trained on.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In this specific scenario, Generative AI can answer better and
                more contextually than regular chatbots, which can only answer
                queries related to FAQs built with known use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if you ask a chatbot on a company page for
                financial services, you can see a chatbot with pre-defined query
                templates. If any of the templates match your queries, you can
                click it and continue the conversations and solve your problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But what if you do not find a match for your query? It will
                return the same conversation template and ask you to choose to
                continue. This is utterly irritating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Training Knowledge AI with the same abilities as Generative AI
                is a faster way to overcome RPA-based chatbot communications
                challenges and reduce the knowledge management gap.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you have business-specific use cases and articles ready to
                build your KB and layer with the power of Knowledge AI, it is
                twice as much faster to generate responses to specific queries
                and provide an answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-knowledge-management-automation">
                  Knowledge AI
                </a>{" "}
                is an LLM and Generative AI-powered chat interface that
                retrieves answers by detecting the contexts and intent of a
                query and provides an NLP-based answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Your business may have HR or IT support-related use cases
                similar to thousands of other functions. So, you can easily
                gather data, prepare your KB articles, and give access to
                Knowledge AI to train it with your business-specific use cases
                and help it solve user problems in real-time.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI-powered straightforward answers for employees on Workativ"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say your user wants to know how to fix a printer paper jam
                issue. A Knowledge AI-powered self-serve chatbot can provide a
                straightforward answer and a supporting article.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At a time when Knowledge AI accelerates knowledge discovery, it
                also reduces friction by eliminating the need to navigate a
                whole KB article and speed up the process of resolving an issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI from Workativ
                </a>{" "}
                allows you to build a massive corpus of data or a large language
                model with Website KB, External KB, and Internal KB. As a
                result, you can build an efficient KB with the ability to create
                custom conversation workflows and FAQ-based templates to handle
                workplace support issues.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Knowledge AI work to improve knowledge search?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Simply put, Knowledge AI is a platform used to upload articles
                with use cases that provide answers to IT or HR-related queries.
                It contains ChatGPT-like features to provide answers to users
                and help them solve problems in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When a query is asked, Knowledge AI searches its large language
                model built with the articles or FAQs of business use cases. It
                finds contextual answers to NLP queries by searching the large
                language mode built on KB articles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                It goes this way 一
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A user asks a query in a chatbot powered by Knowledge AI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Large language models search for a contextual match (for say
                  if it is a PTO inquiry, Knowledge AI searches information for
                  leave balances as well as paid time off)
                </li>
                <li className="font-section-normal-text-testimonials">
                  If Knowledge AI does not surface an answer, it can seamlessly
                  transfer the call to a live agent
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the use cases of Knowledge AI?
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Knowledge AI-backed use cases for employee support"
              />

              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI helps meet various business expectations by
                allowing your people to work at their best. Let’s find these
                significant use cases.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none pl-4 font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Enhancing Tier 1 IT support is significant for any
                    organization. These supports are common to ensure employee
                    productivity. But, when they have no straightforward
                    solution, the time to resolve these issues is prolonged and
                    causes long downtime.
                  </span>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI provides efficacy for FAQs or custom dialogs to be
                widely used for multiple common or average support use cases,
                such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-5">
                <li className="font-section-normal-text-testimonials">
                  Password reset
                </li>
                <li className="font-section-normal-text-testimonials">
                  VPN settings
                </li>
                <li className="font-section-normal-text-testimonials">
                  User provisions
                </li>
                <li className="font-section-normal-text-testimonials">
                  PTO enquiry etc
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI accelerates the pace of Tier 1 support and helps
                solve employees’ problems at scale.{" "}
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none pl-4 font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Self-serve for employee onboarding reduces the time for HR
                    to dedicate to a lengthier process of functions such as
                    documentation, introduction to the team, asset provisions,
                    etc. Since these processes are automated, the new hire does
                    not need to wait long and feel disengaged with the
                    institutions. With pre-defined and automated workflows, a
                    new hire can easily get assets and access to business
                    emails, applications, and so on and so forth.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Work-related knowledge discovery is useful for quick
                    information on the project's progress or company details. If
                    you have Knowledge AI embedded in your intranet platform,
                    such as SharePoint, Google Workspace, or Samepage, you can
                    easily provide access to key information for ongoing
                    projects and company details and keep everyone on the same
                    page to become aware of the progress or any new thing
                    happening across your organization.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of Knowledge AI for your modern workplace
              </h2>

              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="benefits of knowledge ai for employee support efficiency"
              />
              <p class="font-section-normal-text-testimonials line-height-18 mb-1">
                Knowledge AI improves knowledge discovery, making information
                easily accessible and providing multiple benefits for
                organizations to expedite growth.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Employee productivity boost is the foremost advantage of
                    Knowledge AI, which helps reduce downtime and increase
                    uptime by allowing employees to solve problems by themselves
                    using{" "}
                    <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                      ChatGPT or a Generative AI-based self-serve chatbot
                      platform.
                    </a>
                  </span>
                </li>

                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Reduction of repeated requests is possible with Generative
                    AI-powered Knowledge AI search discovery. Your employees are
                    more empowered to solve their problems, raising the number
                    of agent tickets in the next tier, reducing workloads for
                    agents, and solving more critical issues at scale.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Enhanced knowledge repository gives access to information
                    that was otherwise unattainable with KBs without the power
                    of Knowledge AI. As you can upload as many articles with the
                    preferred business use cases, you make information easy to
                    find and help solve a problem.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials  line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>
                    Cost reduction has a positive implication on your balance
                    sheet as you save costs for optimized employee and agent
                    utilization. On top of it, you save on tickets being reduced
                    to the service desks or help desks.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ and Knowledge AI - an outstanding combination to
                redefine your workplace
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ has a leading workplace automation solution to help
                businesses with their HR and IT support automation initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Using conversational AI platforms, Workative has enabled
                businesses to make IT and HR support easy for their people and
                achieve productivity gains.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Companies across information systems, health IT consulting, and
                IT services have leveraged benefits with Workativ conversational
                AI platforms.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Now,{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  we have integrated a large language model or Generative AI
                  into our conversational AI platform known as Knowledge AI to
                  help users build a huge corpus of knowledge
                </a>{" "}
                and help retrieve information at scale for enhanced workplace
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Leveraging our Knowledge AI, you can rapidly build highly
                effective KB resources and provide custom solutions to build
                workflows that solve unique support issues. Choose our Knowledge
                AI to,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials list-style-type-counter">
                  <span>1. </span>
                  <span>Upload KB articles as many as you wish</span>
                </li>
                <li className="font-section-normal-text-testimonials list-style-type-counter">
                  <span>2. </span>
                  <span>
                    {" "}
                    Customize and edit KB articles at any time to provide the
                    latest information at scale
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials list-style-type-counter">
                  <span>3. </span>
                  <span>
                    {" "}
                    Get Hybrid NLU and conversational AI for seamless service
                    desk conversations
                  </span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you use Workativ Knowledge AI, you can have the ability to
                build{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  improved knowledge management
                </a>{" "}
                by constantly making changes to your existing articles as you
                scale. With the updated knowledge bases, you ensure your
                employees always have the latest information to solve problems
                and enhance productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The flexibility and convenience of getting information at scale
                go back to making the life of service desk agents easy and
                helping them efficiently address much more complicated issues,
                resulting in downtime being short-lived and less repetitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you want to explore more about{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI solutions from Workativ, contact us today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why is knowledge management difficult in the modern
                    workplace?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How does inefficient knowledge management impact user
                    experience?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How does Knowledge AI help overcome knowledge discovery
                    challenges?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How does Knowledge AI work to improve knowledge search?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. What are the use cases of Knowledge AI?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Benefits of Knowledge AI for your modern workplace
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Workativ and Knowledge AI - an outstanding combination to
                    redefine your workplace
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                While investigating why the client project had little progress,
                you discovered that your development teams had resource issues.
                Let’s say a sudden necessity was to get some essential software
                applications, and it brings up a couple of pieces of work for
                your DevOps teams.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                All this mundane work can entail preparing a presentation for
                the manager’s approval, sending a letter to HR for budget
                allocation, then HR writing to the CFO for approval, connecting
                back to the finance team for disbursal, and so on and so forth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These are back-and-forth processes and interconnected 一 a lot
                of interactions happen with{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  HR and IT support.
                </a>{" "}
                A single silo results in information slipping through the cracks
                and making it hard for a new person to take the process ahead.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, do you agree that knowledge is key? If information is not
                accessible, productivity issues come up at large. For the same
                reason, your{" "}
                <a href="https://workativ.com/conversational-ai-platform/features">
                  employee support
                </a>{" "}
                team can be forced to handle the repeated issues and delay
                customer service delivery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/contact-us">
                  Knowledge AI, a large language model or LLM-powered solution
                  for conversational AI or chatbot for service desk,
                </a>{" "}
                makes it easy for your team to search for information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Maybe you have a self-serve platform in your workplace and
                leverage automation to some extent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the power of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search">
                  generative AI and large language models
                </a>
                unleashes great efforts to redefine how your employee support
                team works and helps you build resilience into operational
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you do not find Knowledge AI quite convincing for the growth
                of your business, we can give you more reasons why fast
                information delivery or knowledge discovery is essential for a
                modern workplace today to work and deliver business results.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why is knowledge management difficult in the modern workplace?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employees need information to work - this is a universal truth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, when knowledge is fragmented and lies in a silo, knowledge
                is scarce.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A support team cannot work. Your employees look clueless and sit
                idle for hours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, why this happens?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Organizations are not able to manage knowledge effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Today, the number of productivity tools has just gone up. On top
                of it, organizations also use different business tools, such as
                CRM, ERP, ITSM platforms, etc.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Communications across these tools are not synced to provide an
                  integrated, unified view of the information.
                </li>
                <li className="font-section-normal-text-testimonials">
                  In a hybrid work setting, one’s time zone does not match the
                  others. If anyone is unavailable, knowledge delivery can
                  easily be affected.
                </li>
                <li className="font-section-normal-text-testimonials">
                  There is rarely anyone who can take responsibility for keeping
                  knowledge articles updated and organized. So,{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/knowledge-ai-employee-support">
                    knowledge management
                  </a>{" "}
                  becomes difficult.
                </li>
                <li className="font-section-normal-text-testimonials">
                  We can say knowledge is multichannel in more instances rather
                  than knowledge being omnichannel.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Organizations dedicate time to legacy systems.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                As a result,{" "}
                <a href="https://workativ.com/conversational-ai-platform/virtual-agent">
                  self-serve
                </a>{" "}
                cannot reach its full potential and offer employees the
                information they need to perform a task.
              </p>
              <BlogCta
                ContentCta="Automate your enterprise employee support with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The other way, it increases the workload on the employee support
                team.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does inefficient knowledge management impact user
                experience?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge management not done right can result in information
                being old and ineffective.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When this type of KM is used to{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  build chatbot
                </a>{" "}
                conversations such as FAQs or customized responses, employee
                support can become of no help.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The generated responses may either contain out-of-date or
                incomplete information for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say an internal project management tool your employees use has a
                new version. It means it has new features and functionalities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now imagine the existing resource center or KB has no new
                updates added to the document.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A person unaware of the changes to the application would have
                difficulty taking control of the app and face productivity
                issues. It urges him to turn to the self-serve platform.
                Unfortunately, it can only surface the same old tips and provide
                no real help, forcing him to seek an agent’s help.
              </p>
              <h3 className="font-section-sub-header-small-home">
                It is a deeper problem inside.{" "}
              </h3>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt=" limited context window for a normal chatbot"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Employee productivity is wasted if an answer is unavailable
                through FAQs on the{" "}
                <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                  self-serve platform
                </a>{" "}
                or a ticket is on the waiting list for more than a few minutes,
                if not hours.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In many instances, businesses are seen handling the same issues
                repeatedly, causing overutilization of agent time and forcing
                agents to add more time to resolve a common issue. At the same
                time, employees face disengagement due to loss of productivity.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does Knowledge AI help overcome knowledge discovery
                challenges?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Over the past few months, we have heard much about Generative
                AI, which has incredible abilities close to human-level
                intelligence to understand natural language queries and give
                back NLP-based answers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Built on neural networks of deep learning techniques similar to
                human brains, Generative AI can detect the context in a query
                and populate the answer for users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                  However, large language models used as ‘as is’ can be used for
                  Q&A conversations based on the data they are trained on.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In this specific scenario, Generative AI can answer better and
                more contextually than regular chatbots, which can only answer
                queries related to FAQs built with known use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if you ask a chatbot on a company page for
                financial services, you can see a chatbot with pre-defined query
                templates. If any of the templates match your queries, you can
                click it and continue the conversations and solve your problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                But what if you do not find a match for your query? It will
                return the same conversation template and ask you to choose to
                continue. This is utterly irritating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Training Knowledge AI with the same abilities as Generative AI
                is a faster way to overcome RPA-based chatbot communications
                challenges and reduce the knowledge management gap.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you have business-specific use cases and articles ready to
                build your KB and layer with the power of Knowledge AI, it is
                twice as much faster to generate responses to specific queries
                and provide an answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-knowledge-management-automation">
                  Knowledge AI
                </a>{" "}
                is an LLM and Generative AI-powered chat interface that
                retrieves answers by detecting the contexts and intent of a
                query and provides an NLP-based answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Your business may have HR or IT support-related use cases
                similar to thousands of other functions. So, you can easily
                gather data, prepare your KB articles, and give access to
                Knowledge AI to train it with your business-specific use cases
                and help it solve user problems in real-time.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI-powered straightforward answers for employees on Workativ"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say your user wants to know how to fix a printer paper jam
                issue. A Knowledge AI-powered self-serve chatbot can provide a
                straightforward answer and a supporting article.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At a time when Knowledge AI accelerates knowledge discovery, it
                also reduces friction by eliminating the need to navigate a
                whole KB article and speed up the process of resolving an issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI from Workativ
                </a>{" "}
                allows you to build a massive corpus of data or a large language
                model with Website KB, External KB, and Internal KB. As a
                result, you can build an efficient KB with the ability to create
                custom conversation workflows and FAQ-based templates to handle
                workplace support issues.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does Knowledge AI work to improve knowledge search?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Simply put, Knowledge AI is a platform used to upload articles
                with use cases that provide answers to IT or HR-related queries.
                It contains ChatGPT-like features to provide answers to users
                and help them solve problems in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When a query is asked, Knowledge AI searches its large language
                model built with the articles or FAQs of business use cases. It
                finds contextual answers to NLP queries by searching the large
                language mode built on KB articles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                It goes this way 一
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A user asks a query in a chatbot powered by Knowledge AI
                </li>
                <li className="font-section-normal-text-testimonials">
                  Large language models search for a contextual match (for say
                  if it is a PTO inquiry, Knowledge AI searches information for
                  leave balances as well as paid time off)
                </li>
                <li className="font-section-normal-text-testimonials">
                  If Knowledge AI does not surface an answer, it can seamlessly
                  transfer the call to a live agent
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the use cases of Knowledge AI?
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Knowledge AI-backed use cases for employee support"
              />

              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI helps meet various business expectations by
                allowing your people to work at their best. Let’s find these
                significant use cases.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none pl-4 font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Enhancing Tier 1 IT support is significant for any
                    organization. These supports are common to ensure employee
                    productivity. But, when they have no straightforward
                    solution, the time to resolve these issues is prolonged and
                    causes long downtime.
                  </span>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI provides efficacy for FAQs or custom dialogs to be
                widely used for multiple common or average support use cases,
                such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-5">
                <li className="font-section-normal-text-testimonials">
                  Password reset
                </li>
                <li className="font-section-normal-text-testimonials">
                  VPN settings
                </li>
                <li className="font-section-normal-text-testimonials">
                  User provisions
                </li>
                <li className="font-section-normal-text-testimonials">
                  PTO enquiry etc
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI accelerates the pace of Tier 1 support and helps
                solve employees’ problems at scale.{" "}
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none pl-4 font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Self-serve for employee onboarding reduces the time for HR
                    to dedicate to a lengthier process of functions such as
                    documentation, introduction to the team, asset provisions,
                    etc. Since these processes are automated, the new hire does
                    not need to wait long and feel disengaged with the
                    institutions. With pre-defined and automated workflows, a
                    new hire can easily get assets and access to business
                    emails, applications, and so on and so forth.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Work-related knowledge discovery is useful for quick
                    information on the project's progress or company details. If
                    you have Knowledge AI embedded in your intranet platform,
                    such as SharePoint, Google Workspace, or Samepage, you can
                    easily provide access to key information for ongoing
                    projects and company details and keep everyone on the same
                    page to become aware of the progress or any new thing
                    happening across your organization.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Benefits of Knowledge AI for your modern workplace
              </h2>

              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="benefits of knowledge ai for employee support efficiency"
              />
              <p class="font-section-normal-text-testimonials line-height-18 mb-1">
                Knowledge AI improves knowledge discovery, making information
                easily accessible and providing multiple benefits for
                organizations to expedite growth.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Employee productivity boost is the foremost advantage of
                    Knowledge AI, which helps reduce downtime and increase
                    uptime by allowing employees to solve problems by themselves
                    using{" "}
                    <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                      ChatGPT or a Generative AI-based self-serve chatbot
                      platform.
                    </a>
                  </span>
                </li>

                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Reduction of repeated requests is possible with Generative
                    AI-powered Knowledge AI search discovery. Your employees are
                    more empowered to solve their problems, raising the number
                    of agent tickets in the next tier, reducing workloads for
                    agents, and solving more critical issues at scale.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Enhanced knowledge repository gives access to information
                    that was otherwise unattainable with KBs without the power
                    of Knowledge AI. As you can upload as many articles with the
                    preferred business use cases, you make information easy to
                    find and help solve a problem.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials  line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>
                    Cost reduction has a positive implication on your balance
                    sheet as you save costs for optimized employee and agent
                    utilization. On top of it, you save on tickets being reduced
                    to the service desks or help desks.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ and Knowledge AI - an outstanding combination to
                redefine your workplace
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ has a leading workplace automation solution to help
                businesses with their HR and IT support automation initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Using conversational AI platforms, Workative has enabled
                businesses to make IT and HR support easy for their people and
                achieve productivity gains.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Companies across information systems, health IT consulting, and
                IT services have leveraged benefits with Workativ conversational
                AI platforms.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Now,{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  we have integrated a large language model or Generative AI
                  into our conversational AI platform known as Knowledge AI to
                  help users build a huge corpus of knowledge
                </a>{" "}
                and help retrieve information at scale for enhanced workplace
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Leveraging our Knowledge AI, you can rapidly build highly
                effective KB resources and provide custom solutions to build
                workflows that solve unique support issues. Choose our Knowledge
                AI to,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials list-style-type-counter">
                  <span>1. </span>
                  <span>Upload KB articles as many as you wish</span>
                </li>
                <li className="font-section-normal-text-testimonials list-style-type-counter">
                  <span>2. </span>
                  <span>
                    {" "}
                    Customize and edit KB articles at any time to provide the
                    latest information at scale
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials list-style-type-counter">
                  <span>3. </span>
                  <span>
                    {" "}
                    Get Hybrid NLU and conversational AI for seamless service
                    desk conversations
                  </span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you use Workativ Knowledge AI, you can have the ability to
                build{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  improved knowledge management
                </a>{" "}
                by constantly making changes to your existing articles as you
                scale. With the updated knowledge bases, you ensure your
                employees always have the latest information to solve problems
                and enhance productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The flexibility and convenience of getting information at scale
                go back to making the life of service desk agents easy and
                helping them efficiently address much more complicated issues,
                resulting in downtime being short-lived and less repetitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you want to explore more about{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI solutions from Workativ, contact us today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              Conversational AI: The Next Frontier in Enterprise Collaboration
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies"
              className="font-section-normal-text-testimonials"
            >
              Generative AI for Dummies ( what, how, why for companies)
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
